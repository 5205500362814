import React, {
  Children,
  cloneElement,
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  isValidElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import Slider, { Settings } from 'react-slick'
import { Button, ButtonProps, Center, BoxProps, Box } from '@chakra-ui/react'
import { Icon } from './icon'
import { omit } from 'lodash'
import { runIfFunction, RunIfFunctionProps } from '../utils/run-if-function'

export interface CarouselProps extends Settings {
  children?: RunIfFunctionProps<{
    activeIndex: number
  }>
}

export interface CarouselWithNavigatorProps extends CarouselProps {
  navigator?: boolean
  navigatorProps?: Settings
  navigatorItemProps?: BoxProps
  navigatorContainerProps?: BoxProps
}

export interface CarouselArrowProps extends ButtonProps {}

export const Carousel: ForwardRefExoticComponent<CarouselProps> = forwardRef(
  ({ children, ...other }, ref) => {
    return (
      <Slider
        adaptiveHeight
        infinite
        autoplay
        autoplaySpeed={3000}
        speed={1000}
        pauseOnHover={false}
        // swipeToSlide={true}
        prevArrow={<CarouselArrow ml={6} />}
        nextArrow={<CarouselArrow mr={6} />}
        // @ts-ignore
        ref={ref}
        {...other}
      >
        {children}
      </Slider>
    )
  }
)

export const CarouselWithNavigator: FC<CarouselWithNavigatorProps> = ({
  children,
  navigator = true,
  navigatorProps,
  navigatorItemProps,
  navigatorContainerProps,
  ...other
}) => {
  if (!navigator) {
    return (
      <Carousel
        // @ts-ignore
        {...other}
      >
        {children}
      </Carousel>
    )
  }

  const mainRef = useRef<Slider>()
  const subRef = useRef<Slider>()
  const [state, setState] = useState<{
    mainRef: Slider | undefined
    navigatorRef: Slider | undefined
    navigatorItems: ReactNode
  }>({
    mainRef: undefined,
    navigatorRef: undefined,
    navigatorItems: Children.map(children, child => {
      if (isValidElement(child)) {
        return cloneElement(child, navigatorItemProps)
      }
    }),
  })

  useEffect(() => {
    setState(prev => ({
      ...prev,
      mainRef: mainRef.current,
      navigatorRef: subRef.current,
    }))
  }, [])

  const childrenCount = Children.count(children)

  return (
    <>
      <Carousel
        variableWidth={false}
        asNavFor={state.navigatorRef}
        // @ts-ignore
        ref={slider => (mainRef.current = slider)}
        {...other}
      >
        {children}
      </Carousel>
      <Box {...navigatorContainerProps}>
        <Carousel
          slidesToShow={childrenCount - 1}
          variableWidth={false}
          focusOnSelect={true}
          autoplay={false}
          arrows={false}
          asNavFor={state.mainRef}
          // @ts-ignore
          ref={slider => (subRef.current = slider)}
          {...navigatorProps}
        >
          {state.navigatorItems}
        </Carousel>
      </Box>
    </>
  )
}

export const CarouselArrow: FC<CarouselArrowProps> = ({
  className,
  onClick,
  ...other
}) => {
  const isPrev = className?.includes('slick-prev')
  return (
    <Center>
      <Button
        variant={'rounded.outline.white'}
        size={'xs'}
        position={'absolute'}
        top={0}
        bottom={0}
        left={isPrev ? 0 : 'unset'}
        right={!isPrev ? 0 : 'unset'}
        m={'auto'}
        zIndex={1}
        onClick={e => {
          e.stopPropagation()
          if (onClick) {
            onClick(e)
          }
        }}
        {...omit(other, 'style', 'currentSlide', 'slideCount')}
      >
        <Icon kind={isPrev ? 'left-arrow' : 'right-arrow'} />
      </Button>
    </Center>
  )
}

export const CarouselArrowWrapper: FC<CarouselArrowProps> = ({
  className,
  onClick,
  children,
  ...other
}) => {
  const isPrev = className?.includes('slick-prev')
  const leftOrRight = isPrev
    ? {
        left: 0,
      }
    : {
        right: 0,
      }

  return (
    <Center
      position={'absolute'}
      zIndex={1}
      top={0}
      {...leftOrRight}
      {...omit(other, 'style', 'currentSlide', 'slideCount')}
    >
      {runIfFunction(children, { isPrev, onClick })}
    </Center>
  )
}
