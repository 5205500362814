import { ReactNode } from 'react'

export type RunIfFunctionProps<P> = ReactNode | ((...props: P[]) => ReactNode)

export function runIfFunction<T>(
  valueOrFunction: RunIfFunctionProps<T>,
  ...args: T[]
): ReactNode {
  return typeof valueOrFunction === 'function'
    ? valueOrFunction(...args)
    : valueOrFunction
}
